.footer {
  overflow-x: hidden;

  &_top {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #FFF;
    background-color: #3778DE;
  }

  &_top-logo-wrap {
    width: 225px;
    @include breakpoint-down(md) {
      margin-bottom: 26px;
    }

    .logo {
      width: 100%;
      margin: 0 0 24px 0;
      @include breakpoint-down(md) {
        margin-bottom: 12px;
      }
    }

    .copyright {
      font-weight: 400;
      @include breakpoint-down(md) {
        font-size: 16px;
        line-height: 20px;
        font-weight: 200;
      }
    }
  }

  &_top-inside {
    display: flex;
    justify-content: space-between;
    padding: 40px 0;

    @include breakpoint-down(md) {
      display: block;
      padding-bottom: 26px;
    }

    &:last-of-type {
      border-top: 1px solid #6BA5FF;
      align-items: center;
      position: relative;
      @include breakpoint-down(md) {
        justify-content: left;
        border: none;
        padding-top: 0;
      }
    }

    .title {
      font-weight: 600;
      margin-bottom: 16px;
    }

    .phone {
      margin-bottom: 10px;
      display: block;
    }

    .email {
      margin-bottom: 10px;
      display: block;
    }

    .address-wrap {
      font-weight: normal;
      width: 250px;
      @include breakpoint-down(md) {
        width: 100%;
      }

      a {
        margin-top: 10px;
        display: inline-block;
      }

      @include breakpoint-down(md) {
        & + .title {
          display: none;
        }
      }
    }
  }


  &_bottom {
    background-color: #ECF1FF;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #101F33;
    padding: 40px 0 58px;

    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
      margin-bottom: 26px;
    }

    p:last-child {
      margin-bottom: 0;
    }

    @include breakpoint-down(md) {
      padding: 18px 0;

      h3 {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 20px;
      }


    }
  }

  &__nav {
    list-style: none;
    padding: 35px 0 0;
    column-count: 2;
    margin: -5px -50px;

    li {
      padding: 5px 50px;
      &:nth-child(2){
        display: none;
      }
    }

    @include breakpoint-down(md) {
      padding: 0 0 40px;
    }
  }
}

.social-networks {
  list-style: none;
  margin: 0;
  display: flex;
  transform: translate(-10px, 0px);

  @include breakpoint-down(md){
    padding: 0;
  }

  &-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @include breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;
    }

    .title {
      margin-bottom: 0;
    }
  }

  li {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    @include breakpoint-down(md) {
      margin-right: 4px;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    transition: color .3s ease-in-out, border .3s ease-in-out;

    &:hover {
      border: solid 2px #1455D8;
      color: #1455D8;
    }

    i {
      font-size: 20px;

      &.icon-youtube {
        font-size: 18px;
      }

      &.icon-vk {
        font-size: 14px;
      }
    }
  }
}

.search-form {
  position: relative;
  display: none;

  input {
    padding: 12px 44px 11px 20px;
    width: 290px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.5);
  }

  button {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 60px;
    right: 0;
  }

  @include breakpoint-down(md) {
    bottom: -150px;
    margin-top: 0;
  }
}

.subscribe-form {

  &-wrap {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0px, -50%);

    @include breakpoint-down(md) {
      display: none;
    }

    .title-form {
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #0E2773;
    }
  }

  input {
    padding: 12px 20px 11px;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 5px;
    margin-right: 10px;
  }

  button {
    background: #3355C4;
    border-radius: 5px;
    padding: 13px 11px 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
}
