.reviews {
  &__wrap {
    margin: -10px;
    display: flex;
    flex-wrap: wrap;
    min-height: 400px;
    padding-bottom: 80px;
    opacity: 0;
    transition: opacity .5s ease-in-out;

    &.loaded {
      opacity: 1;
    }

    @include breakpoint-down(md) {
      margin: 0;
      flex-direction: column;
    }
  }

  &__item-outside-wrap {
    padding: 10px;
    width: 33.3333%;
    display: flex;
    @include breakpoint-down(md) {
      width: 100%;
    }
  }

  &__item {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background: #fff;
    padding: 28px 30px 15px;
    border-radius: 5px;
    box-shadow: 4px 4px 9px #00000038;
    transition: all .2s ease-in;
    transform: translate(0, 0);
    min-height: 250px;

    &:hover {
      box-shadow: 4px 5px 10px #00000038;
      transform: translate(0px, -3px);
    }

    .wrap-inside {
      margin-bottom: auto;
    }

    .num {
      font-weight: 600;
      border-radius: 5px;
      font-size: 12px;
      line-height: 15px;
      color: #fff;
      height: 22px;
      display: inline-flex;
      align-items: center;
      padding: 0 14px;

      &.blue {
        background: #1877F2;
      }

      &.dark-blue {
        background: #0E2773;
      }

      &.red {
        background: #EE5252;
      }
    }

    .num-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .date-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      padding: 17px 0;
      border-bottom: 1px solid rgba(87, 87, 86, 0.2);

      i {
        font-size: 22px;
        margin-right: 14px;
        color: #1877F2;
      }
    }

    .date {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #1877F2;
    }

    .title {
      margin-bottom: 20px;
    }

    .description {
      font-weight: 300;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 12px;
      height: 0;
      overflow: hidden;
    }

    .btn_blue-border {
      width: 180px;
      margin: 0 auto;
      display: block;
    }
  }
}


.all-reviews-wrap {
  padding: 50px 0 70px;
  text-align: center;
}

.all-reviews {
  min-width: 260px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: #FFFFFF;
  border: 2px solid #575756;
  box-sizing: border-box;
}
