.career-page {
    .top-banner__img {
        height: 390px;
        top: -120px;
        right: 0;
        @include breakpoint-down(md) {
            top: 0;
            height: auto;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .container-fluid{
        min-height: 400px;
    }

    .career {
        &-items {
            display: flex;
            flex-wrap: wrap;
            margin: -25px -10px 50px -10px;
        }

        &-item-wrap-outside {
            width: 50%;
            padding: 25px 10px;
            @include breakpoint-down(md) {
                width: 100%;
                padding: 15px 10px;
            }
        }

        &-item {
            transition: box-shadow .2s ease-in-out;
            background: #f5f7f9;
            padding: 25px 26px 90px;
            color: #101F33;
            min-height: 100%;
            box-shadow: 0 15px 25px rgba(138, 149, 158, 0.15);
            border-radius: 5px;
            position: relative;
            @include breakpoint-down(md) {
                padding: 15px 15px 30px;
            }
            &:hover {
                box-shadow: 0 15px 25px rgba(138, 149, 158, 0.25);
            }

            .title {
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 20px;
                line-height: 20px;
                color: #101F33;
            }

            .description {
                font-weight: normal;
                font-size: 14px;
                line-height: 17px;
                @include breakpoint-down(md){
                    margin-bottom: 20px;
                }
            }

            .btn-wrap {
                position: absolute;
                bottom: 25px;
                left: 26px;
                right: 26px;
                display: flex;
                justify-content: space-between;

                .animate-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 48%;

                    @include breakpoint-down(md){
                        width: 100%;
                        margin-bottom: 15px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }

                @include breakpoint-down(md) {
                    position: relative;
                    bottom: 0;
                    left: 0;
                    flex-wrap: wrap;
                }
            }
        }
    }
}
