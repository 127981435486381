.filter-wrap {
    display: flex;
    margin-bottom: 40px;
    @include breakpoint-down(md) {
      display: block;
    }

    .select {
        display: block;
        min-width: 380px;
        position: relative;
        margin-right: 100px;
        @include breakpoint-down(md) {
            display: block;
            margin-right: 0;
            margin-bottom: 20px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .new-select {
        position: relative;
        display: inline-block;
        align-items: center;
        user-select: none;
        font-weight: 300;
        font-size: 14px;
        line-height: 28px;
        color: #000;
        padding: 4px 10px 4px 20px;
        background: #FFFFFF;
        border: 2px solid rgba(87, 87, 86, 0.05);
        box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.1);
        min-width: 380px;
        border-radius: 5px;
    }

    .new-select__list {
        position: absolute;
        top: 100%;
        left: 0;
        border: 1px solid #ced4da;
        cursor: pointer;
        right: 0;
        z-index: 6;
        background-color: #fff;
        user-select: none;
    }

    .new-select__list.on {
        display: block;
    }

    .new-select__item span {
        display: block;
        padding: 10px 15px;
    }

    .new-select__item span:hover {
        color: $red;
    }

    .new-select:after {
        content: '';
        display: block;
        position: absolute;
        right: 15px;
        bottom: 11px;
        -webkit-transition: all .27s ease-in-out;
        -o-transition: all .27s ease-in-out;
        transition: all .27s ease-in-out;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #000;
    }

    .new-select:before {
        content: '';
        display: block;
        position: absolute;
        right: 15px;
        top: 11px;
        -webkit-transition: all .27s ease-in-out;
        -o-transition: all .27s ease-in-out;
        transition: all .27s ease-in-out;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #000;
    }
}