.documentation-page{

  .documentation{
    &-items{
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 60px;
      padding-top: 40px;
      @include breakpoint-down(md){
        padding: 0;
      }
    }

    &-item-wrap-outside{
      width: 50%;
      padding: 5px 25px;
      @include breakpoint-down(md){
        width: 100%;
        padding: 25px 0;
      }
    }

    &-item{
      display: inline-flex;
      align-items: center;

      .title{
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #000;
      }

      .img{
        margin-right: 30px;
        font-size: 30px;
      }
    }
  }
}

.document-link-wrap{
  position: relative;

  a{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}


.certificates{
  &-item-wrap-outside{
    width: 48%;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 4px 4px 9px #00000038;
    transition: all .2s ease-in;
    transform: translate(0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 250px;

    &:hover{
      box-shadow: 4px 5px 10px #00000038;
      transform: translate(0px, -3px);
    }

    @include breakpoint-down(md){
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
    }

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
