.nav-main {
    ul {
        @include breakpoint-down(md) {
            flex-direction: column;
        }

        & > li {
            margin-right: 30px;
            position: relative;
            color: #101F33;
            transition: color .1s ease-in-out;

            &:hover {
                color: #1877F2;
            }

            &:last-child {
                margin-right: 0;
                .dropdown-menu{
                    li:last-child{
                        display: none;
                    }
                }
            }

            @include breakpoint-down(md) {
                margin-bottom: 12px;
                margin-right: 0;

                &:last-child {
                    margin-right: 0;
                    margin-bottom: 0;
                }
            }

            a {
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                @include breakpoint-down(md){
                    font-size: 14px;
                    line-height: 12px;
                }
            }
        }
    }
}

.dropdown-toggle::after {
    display: none;
}

.list-inline > li {
    span {
        cursor: pointer;
        @include breakpoint-down(md) {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #101F33;
            display: block;
        }
    }
}

.dropdown-menu {
    background: #FFFFFF;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
    border-radius: 0;
    padding: 20px;
    border: none;
    top: 100%;
    display: block;
    opacity: 0;
    pointer-events: none;
    transition: opacity .1s ease-in-out;

    &.show {
        opacity: 1;
        pointer-events: inherit;
    }

    @include breakpoint-down(md) {
        position: relative !important;
        transform: translate(0) !important;
        background: transparent;
        box-shadow: none;
        border-radius: 0;
        padding: 5px 10px 10px;

        &:after {
            content: '';
            position: absolute;
            background: rgba(196, 196, 196, 0.1);
            left: -100%;
            right: -300%;
            top: 0;
            bottom: 0;
            display: none;
        }
    }

    li {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@include breakpoint-down(md){
    .mydropdownjQuery{
        .dropdown-menu{
            .mydropdownjQuery{
                margin: 0 0 8px 0;

                .dropdown-toggle{
                    font-size: 14px;
                }
            }
        }
    }

    .nav-main + div{
        display: none;
    }
}
