.article-page{
    padding: 50px 0;
    font-size: 17px;
    line-height: 22px;
    color: #000;
    @include breakpoint-down(md){
        padding: 25px 0;
    }

    .img{
        float: left;
        margin: 0 20px 20px 0;
        width: 500px;
        height: 400px;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;     
        }

        @include breakpoint-down(md){
            float: none;
            margin: 0 0 20px;
            width: 100%;
        }
    }
}
