.invest-steps {
    padding: 80px 0;
    @include breakpoint-down(md){
        padding: 40px 0;
    }

    .btn_blue {
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
    }

    h2 {
        color: #FFF;
        margin-bottom: 40px;

        @include breakpoint-down(md) {
            margin-bottom: 20px;
            font-weight: 600;
            font-size: 22px;
            line-height: 27px;
        }
    }

    &__wrap {
        display: flex;
        min-height: 176px;
        align-items: stretch;

        @include breakpoint-down(md) {
            flex-direction: column;
            align-items: center;
        }
    }

    &__item {
        color: #fff;
        position: relative;
        padding: 40px 0;
        width: 25%;

        &::after {
            content: '';
            @include stretch;
        }

        @include breakpoint-down(md){
            width: 100%;
            padding: 20px 16px;
            margin-bottom: 8px;
            &:last-of-type{
                margin-bottom: 0;
            }
        }

        &:nth-child(1) {
            z-index: 4;
            &::after {
                background-color: #1C1E29;
                background: linear-gradient(134.16deg, #07263D 9.73%, #1C1E29 78.02%);
                left: -100vw;
                @include breakpoint-down(md){
                    left: 0;
                }
            }
        }

        &:nth-child(2) {
            z-index: 3;
            padding-left: 60px;
            @include breakpoint-down(md){
                padding-left: 16px;
            }
            &::after {
                background: #3D556D;
                left: -100%;
                @include breakpoint-down(md){
                    left: 0;
                }
            }
        }

        &:nth-child(3) {
            color: #5B5B5B;
            padding-left: 80px;
            @include breakpoint-down(md){
                padding-left: 16px;
            }
            &::after {
                background: #EDEDED;
                left: -100%;
                @include breakpoint-down(md){
                    left: 0;
                }
            }
        }

        &:nth-child(4) {
            color: #8D8F91;
            z-index: 2;
            padding-left: 60px;
            @include breakpoint-down(md){
                padding-left: 16px;
            }
            &::after {
                background: #FFFFFF;
                right: -100vw;
                @include breakpoint-down(md){
                    right: 0;
                }
            }
        }
    }

    &__num {
        font-style: normal;
        font-weight: bold;
        font-size: 60px;
        line-height: 73px;
        margin-bottom: 8px;
        position: relative;
        z-index: 2;
        @include breakpoint-down(md){
            font-weight: bold;
            font-size: 40px;
            line-height: 49px;
            position: absolute;
            top: 20px;
            left: 16px;
        }
    }

    &__title {
        position: relative;
        z-index: 2;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 12px;
        @include breakpoint-down(md){
            padding-left: 65px;
            margin-top: 15px;
        }
    }

    &__description {
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        position: relative;
        z-index: 2;
    }
}
