.events {
  .title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    h2 {
      margin-bottom: 0;
    }

    a {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #1877F2;
      padding: 0;
      border: none;
    }
  }

  .activity-item {
    @include breakpoint-down(md) {
      position: relative;
    }
  }

  .event-slider {
    &__wrap {
      display: flex;
      min-height: 406px;

      @include breakpoint-down(md) {
        display: block;
      }
    }

    &__img {
      width: 50%;
      border-radius: 5px;
      overflow: hidden;
      @include breakpoint-down(md) {
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__info {
      width: 50%;
      padding: 0 0 70px 40px;
      position: relative;

      @include breakpoint-down(md) {
        width: 100%;
        padding: 0 0 80px;
      }
    }

    &__title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 26px;
    }

    &__format {
      font-size: 14px;
      line-height: 17px;
      color: #1877F2;
      text-transform: uppercase;
      margin-top: 8px;

      @include breakpoint-down(md) {
        position: absolute;
        font-size: 10px;
        line-height: 13px;
        right: 0;
        bottom: 60px;
      }
    }

    &__desc {
      color: #101F33;
      font-size: 16px;
      line-height: 20px;
    }

    &__btn {
      height: 54px;
      padding: 0 84px;
      position: absolute;
      bottom: 0;
      left: 40px;
    }

    .btn-wrap {
      position: relative;
      height: 12px;
      margin: 40px 0 60px;

      .swiper-pagination {
        left: 0;
        right: 0;
        top: 0;
      }

      .swiper-pagination-clickable .swiper-pagination-bullet {
        height: 12px;
        width: 12px;
        margin-right: 6px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
