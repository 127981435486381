.shares-page {
    .description-block {
        display: flex;
        margin-bottom: 116px;
        @include breakpoint-down(md) {
            display: block;
            margin-bottom: 40px;
        }

        .left {
            width: 45%;
            padding-right: 119px;
            @include breakpoint-down(md) {
                width: 100%;
                padding-right: 0;

                .btn.animate-btn{
                    width: 100%;
                }
            }
        }

        .right {
            width: 55%;
            padding-top: 30px;
            @include breakpoint-down(md) {
                display: none;
            }
        }

        &__img {
            height: 393px;
            border-radius: 5px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .text-item {
        display: flex;
        font-size: 16px;
        line-height: 20px;
        color: #101F33;
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 40px;
        }

        i {
            color: #1877F2;
            margin-right: 20px;
            font-size: 24px;
        }
    }
}

.strategy-wrap {
    display: flex;
    margin-bottom: 80px;
    @include breakpoint-down(md){
        display: block;
        margin-bottom: 40px;
    }

    h2 {
        margin-bottom: 30px;
        @include breakpoint-down(md){
            margin-bottom: 16px;
        }
    }

    .left {
        width: calc(100% - 580px);
        padding: 40px 100px 0 0;
        @include breakpoint-down(md){
            width: 100%;
            margin-bottom: 40px;
            padding: 0;
        }

        .desc {
            font-size: 16px;
            line-height: 20px;
            color: #101F33;
        }

        .img {
            max-width: 318px;
            margin: 50px auto 0;
            @include breakpoint-down(md){
                margin: 20px auto 0;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .right {
        width: 580px;
        background: #F5F7F9;
        box-shadow: 0 15px 25px rgba(138, 149, 158, 0.15);
        border-radius: 5px;
        padding: 40px 26px 80px;
        font-size: 16px;
        line-height: 20px;
        color: #101F33;
        @include breakpoint-down(md){
            width: 100%;
            padding: 26px 15px 40px;
        }

        .bold {
            font-weight: 600;
        }

        p {
            margin-bottom: 20px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.text-block-between {
    display: flex;
    margin-bottom: 80px;
    @include breakpoint-down(md){
        display: block;
        margin-bottom: 40px;
    }

    h2 {
        position: relative;
        z-index: 2;
        @include breakpoint-down(md){
            margin-bottom: 14px;
        }
    }

    .desc {
        position: relative;
        z-index: 2;
        font-size: 16px;
        line-height: 20px;
    }

    .left,
    .right {
        width: 50%;
        position: relative;
        padding-top: 128px;
        padding-bottom: 128px;
        @include breakpoint-down(md){
            width: 100%;
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }

    .left {
        padding-right: 100px;
        color: #fff;
        @include breakpoint-down(md){
            padding: 40px 15px;
        }

        .top-line {
            margin-top: 40px;
            border-top: 1px solid #85AFFA;
            padding-top: 40px;
            color: #000204;
        }

        &::after {
            content: '';
            background: #2F6BD7;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: -100%;
            @include breakpoint-down(md){
                left: 0;
            }
        }
    }

    .right {
        padding-left: 100px;
        @include breakpoint-down(md){
            padding: 40px 15px;
        }

        .top-line {
            margin-top: 40px;
            border-top: 1px solid #DDDFE1;
            padding-top: 40px;
            color: #636E79;
        }

        &::after {
            content: '';
            background: #F5F7F9;
            position: absolute;
            top: 0;
            bottom: 0;
            right: -100%;
            left: 0;
            @include breakpoint-down(md){
                right: 0;
            }
        }
    }
}

.text-block-bottom {
    .text-items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: -10px -10px 70px;
        @include breakpoint-down(md){
            display: block;
        }
    }

    .text-item-wrap {
        width: 33.3333%;
        display: flex;
        padding: 10px;
        @include breakpoint-down(md){
            width: 100%;
        }
    }

    .text-item {
        margin-bottom: 0;
        flex-direction: column;
        align-items: center;
        border: 1px solid #101f3345;
        border-radius: 5px;
        padding: 70px 34px 30px;
        &:last-child {
            margin-bottom: 0;
        }

        @include breakpoint-down(md){
            padding: 35px 34px 30px;
        }

        .img {
            margin-bottom: 40px;
        }

        h3 {
            margin-bottom: 18px;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            color: #101F33;
        }

        .description {
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: #101F33;
        }
    }
}
