.partners-page {

    .partners {
        &__wrap-item {
            margin-bottom: 75px;
            min-height: 50vh;
            @include breakpoint-down(md) {
                margin-top: 50px;
            }
        }

        &__item {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            margin-bottom: 80px;
            min-height: 250px;
            box-shadow: 0 15px 25px rgba(138, 149, 158, 0.15);
            border-radius: 5px;
            padding: 25px;
            @include breakpoint-down(md) {
                display: block;
            }

            &:last-child {
                margin-bottom: 20px;
            }

            &.revert {
                flex-direction: row-reverse;
            }
        }

        &__img {
            width: 250px;
            height: 250px;
            box-shadow: 0 15px 25px rgba(138, 149, 158, 0.15);
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 30px;
            @include breakpoint-down(md) {
                margin: 0 auto 50px;
            }

            img {
                max-width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &__info {
            width: calc(100% - 330px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @include breakpoint-down(md) {
                width: 100%;

                div:last-child {
                    display: flex;
                    justify-content: center;

                    @include breakpoint-down(md) {
                        display: block;
                    }
                }


                .link-wrap {
                    text-align: center;
                }
            }

            .title {
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
                color: #101F33;
            }

            .description {
                font-size: 14px;
                line-height: 17px;
                color: #101F33;
            }
        }
    }
}

.modal-partners-login {
    padding-top: 20px;

    .item {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        @include breakpoint-down(md){
            flex-direction: column;
        }

        .img{
            width: 200px;
            height: 70px;
            margin-right: 20px;

            img{
                max-width: 100%;
                height: 100%;
                //object-fit: cover;
            }
        }
    }
}
