.benefit-wrap {
    display: flex;
    min-height: 418px;
    padding: 80px 0;
    align-items: stretch;

    &-outside {
        background: #F5F7F9;
    }

    @include breakpoint-down(md) {
        flex-direction: column-reverse;
        min-height: 100%;
    }

    .left {
        width: 50%;
        @include breakpoint-down(md) {
            width: 100%;
            margin-top: 50px;
        }
    }
}

.benefit {
    &__img {
        height: 100%;
        text-align: center;

        img {
            max-width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__info {
        padding-left: 90px;
        @include breakpoint-down(md) {
            padding-left: 0;
        }
    }

    &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 34px;
        line-height: 41px;
        color: #101F33;
        margin-bottom: 36px;
    }

    &__subtitle {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #000;
        margin-bottom: 17px;
        display: flex;
        align-items: center;

        i {
            margin-right: 12px;
            color: #1877F2;
            line-height: 20px;
            font-size: 28px;
        }
    }

    &__description {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #101F33;

        @include breakpoint-down(md) {
            width: 100%;
        }
    }

    &__info-item {
        &:not(:last-of-type) {
            margin-bottom: 30px;
        }
    }
}

.idea-date {

    &__item {
        @include breakpoint-down(md) {
            margin-bottom: 28px;
            text-align: center;
        }
    }

    &-wrap {
        display: flex;
        justify-content: space-between;
        //margin: 0 -45px;
        @include breakpoint-down(md) {
            display: block;
        }

        .little {
            width: 30%;
            display: flex;
            justify-content: center;
            @include breakpoint-down(md) {
                width: 100%;
                display: block;
            }
        }

        .big {
            width: 40%;
            display: flex;
            justify-content: center;

            @include breakpoint-down(md) {
                width: 100%;
                display: block;
            }
        }
    }

    &__item-wrap {
        //padding: 0 20px;
    }

    &__title {
        margin-bottom: 20px;
    }

    &__img {
        height: 540px;
        overflow: hidden;

        @include breakpoint-down(md) {
            overflow: scroll;
        }

        img {
            max-width: 100%;
            border-radius: 6px;
            //height: 100%;
            //object-fit: cover;
        }
    }
}

.show-all-idea {
    padding: 17px 75px 16px;
    font-size: 14px;
    line-height: 17px;
    color: #0E2773;
    box-sizing: border-box;
    display: inline-block;

    @include breakpoint-down(md){
        padding: 17px 75px 16px !important;
    }
}

.idea-date-btn-wrap {
    margin-bottom: 50px;

    @include breakpoint-down(md) {
        &.mob {
            transform: translate(0);
            margin-top: 20px;
        }
    }
}
