.ideas-wrap {
    display: flex;
    margin: -10px -10px 0;
    flex-wrap: wrap;
    min-height: 300px;

    &_outside{
        background: #F5F7F9;
        padding: 80px 0;
        margin-bottom: 80px;

        .title-wrap{
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;

            h2{
                margin-bottom: 0;
            }

            .show-all-idea{
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #1877F2;
                padding: 0;
                border: none;
            }
        }
    }
}

.idea {
    &__item {
        background-color: #fff;
        padding: 28px 22px 100px;
        position: relative;
        box-shadow: 4px 4px 9px #00000038;
        border-radius: 5px;
        transition: all .2s ease-in;
        transform: translate(0, 0);

        &:hover {
            box-shadow: 4px 5px 10px #00000038;
            transform: translate(0px, -3px);
        }
    }

    &__item-wrap {
        padding: 10px 10px;
        width: 25%;

        @include breakpoint-down(md) {
            padding: 0;
            width: 100%;
        }
    }

    &__item-head {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EEEEEE;
        padding-bottom: 33px;
        margin-bottom: 20px;
    }

    &__date {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #101F33;
        display: flex;
        align-items: center;

        i{
            margin-right: 8px;
            color: #1877F2;
            font-size: 18px;
            line-height: 17px;
        }
    }

    &__img {
        width: 60px;
        height: 60px;
        margin-right: 24px;

        img {
            object-fit: contain;
            height: 100%;
            width: 100%;
        }
    }

    &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #101F33;
        margin-bottom: 10px;
    }

    &__info {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #000000;

        .bold {
            font-weight: 700;
        }

        p {
            margin-bottom: 16px;
            display: flex;
            justify-content: space-between;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: #000;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &__more-link {
        border: 1px solid #1877F2;
        box-sizing: border-box;
        border-radius: 5px;
        position: absolute;
        right: 34px;
        left: 34px;
        bottom: 24px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #1877F2;
    }
}

.fihished-idea {
    .idea__item-wrap {
        position: relative;
    }

    .percent {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 4;
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 20px;

        &.up {
            color: #1455D8;
        }

        &.down {
            color: #BE1736;
        }
    }

    .idea__item {
        opacity: 0.3;
    }
}

.idea-date-wrap{
    margin-bottom: 90px;
}

