.start-invest {
    background: linear-gradient(180deg, #2C71DB 0%, #3757CA 100%);
    min-height: 240px;
    position: relative;
    margin-bottom: 80px;
    @include breakpoint-down(md){
        margin-bottom: 40px;

        button{
            width: 100%;
        }
    }

    &::before {
        content: '';
        @include stretch();
        background-image: url("../images/start-invest-bg.svg");
        @include breakpoint-down(md){
            display: none;
        }
    }

    &__inside{
        position: relative;
        z-index: 3;
        padding-top: 60px;
        @include breakpoint-down(md){
            padding-top: 30px;
            padding-bottom: 30px;
        }

        button{
            background: #FFFFFF;
        }
    }

    &__info {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 37px;
        color: #FFF;

        .title{
            margin-bottom: 34px;
            @include breakpoint-down(md){
                font-weight: 600;
                font-size: 19px;
                line-height: 23px;
            }
        }
    }

    &__img{
        position: absolute;
        right: 0;
        top: -30px;
        @include breakpoint-down(md){
            position: relative;
            top: 0;
            margin-bottom: 20px;
            text-align: center;
        }
    }
}
