.card{
  border-radius: 0;
  background-color: transparent;
  border: 1px solid rgba(87, 87, 86, 0.2);
  border-left: none;
  border-right: none;
  &:not(:first-child){
    border-top: none;
  }
}

.card-header{
  border: none;
  padding: 10px 0;
  background-color: inherit;
  cursor: pointer;
  
  h5{
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    i{
      color: #1438A5;
      font-size: 16px;
      transition: transform .3s ease-in;
    }
    
    &[aria-expanded="false"]{
      i{
        transform: rotateX(0);
      }
    }
  
    &[aria-expanded="true"]{
      font-weight: 600;
      i{
        transform: rotateX(180deg);
      }
    }
  }
}

.card-body{
  background: rgba(196, 196, 196, 0.1);
  position: relative;
  margin-bottom: 24px;
  padding: 5px 5% 5px 50px;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #000;
  
  &::after{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(196, 196, 196, 0) 100%);
    height: 10px;
  }
  
  &::before{
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(196, 196, 196, 0) 100%);
    transform: rotate(-180deg);
    height: 10px;
  }
}
