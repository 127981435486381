.product-company {
    margin-bottom: 80px;
    @include breakpoint-down(md){
        margin-bottom: 40px;
    }

    &__wrap {
        display: flex;
        flex-wrap: wrap;
        margin: -10px;
        @include breakpoint-down(md){
            display: block;
            margin: 0;
        }
    }

    &__item-wrap {
        width: 50%;
        padding: 10px;
        display: flex;
        a{
            display: flex;
        }
        @include breakpoint-down(md){
            width: 100%;
            padding: 0;
            margin-bottom: 10px;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }

    &__item {
        background: #FFF;
        box-shadow: 0px 15px 25px rgba(138, 149, 158, 0.15);
        border-radius: 10px;
        padding: 21px 50px;
        transition: background .3s ease-in;
        display: flex;
        @include breakpoint-down(md){
            padding: 16px;
        }

        &:hover {
            background: #252D54;

            .product-company__item-title {
                color: #fff;
            }

            .product-company__item-description {
                color: #fff;
            }
        }
    }

    &__item-img {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 24px;

        &.light-green {
            background: #62A555;
        }

        &.green {
            background: #00AA8B;
        }

        &.blue {
            background: #45A1F0;
        }

        &.purple {
            background: #6852EE;
        }

        &.orange {
            background: #EE8C52;
        }

        &.red {
            background: #EE5252;
        }
    }

    &__item-title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #31385C;
        margin-bottom: 6px;
        transition: color .2s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include breakpoint-down(md){
            font-size: 16px;
            line-height: 20px;
        }

        i {
            font-size: 16px;
        }
    }

    &__item-description {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #5A5C6D;
        padding-right: 10%;
        transition: color .2s ease-in-out;
        @include breakpoint-down(md){
            padding-right: 0;
            font-size: 14px;
            line-height: 17px;
        }
    }

    &__info-wrap{
        flex: 1;
    }
}
