  .certificates{
    &-items{
      display: flex;
      flex-wrap: wrap;
      opacity: 0;
      transition: opacity .5s ease-in-out;
      justify-content: center;
      gap: 20px;

      &.loaded {
        opacity: 1;
      }

      @include breakpoint-down(md){
        flex-wrap: wrap;
        flex-direction: column;
        margin: 0 0 30px 0;

    }

    &-item-wrap-outside{
      width: 25%;
      padding: 10px;


      @include breakpoint-down(md){
        width: 100%;
      }
    }
    
    &-item{
      a{
        //opacity: .5;
        //transition: all .3s ease-in-out;
        //filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0));
        //
        //&:hover{
        //  opacity: 1;
        //  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.1));
        //}
        
        img{
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
