.team-items {
    display: flex;
    flex-wrap: wrap;
    margin: -25px -20px 100px;
    opacity: 0;
    transition: opacity .5s ease-in-out;
    min-height: 400px;

    &.loaded {
        opacity: 1;
    }

    @include breakpoint-down(md) {
        flex-direction: column;
        margin: -25px -20px 40px;
    }
}

.team-item {
    padding: 20px 26px;
    transition: box-shadow .2s ease-in-out;
    background: #fff;
    display: flex;
    height: 100%;
    width: 100%;
    box-shadow: 4px 4px 9px #00000038;
    border-radius: 5px;

    @include breakpoint-down(md) {
        flex-direction: column;
        padding: 20px 0;
        align-items: center;
    }

    &-wrap-outside {
        padding: 25px 20px;
        width: 50%;
        min-height: 280px;
        display: flex;

        @include breakpoint-down(md) {
            width: 100%;
            padding: 15px 20px;
        }
    }

    &__img {
        height: 250px;
        width: 200px;
        border-radius: 5px;
        overflow: hidden;
        @include breakpoint-down(md) {
            margin-bottom: 20px;
            width: 90%;
            height: 90%;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__info {
        width: calc(100% - 200px);
        padding-left: 35px;
        font-size: 14px;
        line-height: 17px;
        color: #101F33;
        position: relative;
        @include breakpoint-down(md) {
            width: 100%;
            padding: 0 8px;
        }
    }

    &__name {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
    }

    &__position {
        margin-bottom: 15px;
    }

    &__description {
        padding-bottom: 50px;
        @include breakpoint-down(md) {
            padding-bottom: 30px;
        }
    }


    .social-links {
        color: rgba(87, 87, 86, 0.4);

        a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: solid 1px rgba(87, 87, 86, 0.4);
            transition: all .2s ease-in-out;
            font-size: 10px;
            box-shadow: 4px 5px 10px rgb(0 0 0 / 0%);
            transform: translate(0px, 0px);

            &:not(:last-child) {
                margin-right: 10px;
            }

            &:hover {
                color: #1877F2;
                border: solid 1px #1877F2;
                box-shadow: 4px 5px 10px rgb(0 0 0 / 11%);
                transform: translate(0px, -1px);
            }
        }
    }

    .email {
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        text-align: right;
        color: #101F33;
        transition: all .2s ease-in-out;
        text-shadow: 4px 5px 10px rgb(0 0 0 / 0%);
        transform: translate(0px, 0px);

        &:hover {
            color: #1877F2;
            text-shadow: 4px 5px 10px rgb(0 0 0 / 11%);
            transform: translate(0px, -1px);
        }
    }


    .bottom-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        right: 0;
        left: 75px;
        bottom: 0;

        @include breakpoint-down(md) {
            position: relative;
            left: 0;
        }
    }
}
