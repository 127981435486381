.dictionary-page {
    .top-banner {

        &__img {
            height: 390px;
            top: -60px;
            width: 380px;
            @include breakpoint-down(md) {
                width: 100%;
                height: auto;
                top: 0;
            }
        }
    }

    h2 {
        @include breakpoint-down(md) {
            margin-bottom: 16px;
        }
    }

    .card {
        &__wrap {
            &:last-of-type {
                margin-bottom: 80px;
            }

            @include breakpoint-down(md) {
                width: 100%;
                margin-bottom: 20px;
                &:last-of-type {
                    margin-bottom: 40px;
                }
            }

            &:hover {
                .card {
                    //box-shadow: 0 15px 25px rgba(138, 149, 158, 0.25);
                }
            }
        }
    }

    .card__wrap:not(:first-child) {
        .card {
            border-top: none;
        }
    }

    @include breakpoint-down(md){
        .card-body {
            padding: .9375rem;
        }
    }
}

.dictionary-item__wrap {


    //.card {
    //    width: 80%;
    //    box-shadow: 0 15px 25px rgba(138, 149, 158, 0.15);
    //    border-radius: 5px;
    //    background: #f5f7f9;
    //    border: none;
    //    padding: 15px 20px;
    //    transition: box-shadow .2s ease-in-out;
    //    @include breakpoint-down(md) {
    //        box-shadow: 0 10px 10px rgba(0, 0, 0, .2);
    //        width: 100%;
    //    }
    //}

    //.card-header {
    //    padding: 0;
    //
    //    h5 {
    //        font-weight: 600;
    //        font-size: 19px;
    //        line-height: 33px;
    //        color: #101F33;
    //        position: relative;
    //
    //        &[aria-expanded="true"] {
    //            .wrap-icon {
    //                transform: rotateX(0);
    //                bottom: 0;
    //            }
    //        }
    //
    //        &[aria-expanded="false"] {
    //            .wrap-icon {
    //                transform: rotateX(180deg);
    //                bottom: 0;
    //            }
    //        }
    //
    //        .wrap-icon {
    //            position: absolute;
    //            right: 0;
    //            margin: 0 auto;
    //            width: 50px;
    //            bottom: 0;
    //            transition: all .2s ease;
    //
    //            i {
    //                font-size: 12px;
    //                color: #1877F2;
    //            }
    //        }
    //    }
    //}

    //.card-body {
    //    font-size: 16px;
    //    line-height: 22px;
    //    color: #101F33;
    //    margin-bottom: 0;
    //    padding: 20px 0;
    //    background-color: transparent;
    //    margin-top: 20px;
    //    border-top: 1px solid #1877F2;
    //
    //    &::before,
    //    &::after {
    //        display: none;
    //    }
    //}
}
