.company {

  &__top-info {
    display: flex;
    margin-bottom: 80px;
    @include breakpoint-down(md) {
      display: block;
      margin-bottom: 40px;
    }

    .left {
      width: 50%;

      h2 {
        display: none;
      }

      @include breakpoint-down(md) {
        width: 100%;

        h2 {
          display: block;
        }
      }

      .img {
        border-radius: 5px;
        overflow: hidden;
        height: 380px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .right {
      width: 50%;
      padding-left: 40px;
      @include breakpoint-down(md) {
        width: 100%;
        padding-left: 0;
        padding-top: 30px;

        h2 {
          display: none;
        }
      }

      .info {
        font-size: 16px;
        line-height: 20px;
        color: #101F33;
      }
    }
  }

  .start-invest {
    &__inside {
      padding: 110px 0;
      @include breakpoint-down(md) {
        padding: 30px 0 40px;
      }

      .title {
        font-weight: 600;
        font-size: 30px;
        line-height: 37px;
        margin-bottom: 38px;
        width: 756px;
        color: #FFFFFF;
        @include breakpoint-down(md) {
          width: 100%;
          font-weight: 600;
          font-size: 19px;
          line-height: 23px;
          padding-top: 40px;
        }
      }
    }

    &__img {
      top: 34px;
      @include breakpoint-down(md) {
        position: relative;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

        img {
          max-width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  a.animate-btn {
    background: #FFFFFF;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    width: 300px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1877F2;
    @include breakpoint-down(md) {
      width: 100%;
    }
  }
}

.our-values {
  padding: 80px 0;
  background: #F5F7F9;
  @include breakpoint-down(md) {
    padding: 40px 0;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin: -30px -50px;
    @include breakpoint-down(md) {
      display: block;
      margin: -15px -50px;
    }
  }

  &__item-wrap {
    width: 33.3333%;
    padding: 30px 50px;
    @include breakpoint-down(md) {
      width: 100%;
      padding: 15px 50px;
    }
  }

  &__item-img {
    width: 60px;
    height: 60px;
    margin-bottom: 18px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__item-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
    color: #101F33;
  }

  &__item-description {
    font-size: 16px;
    line-height: 20px;
    color: #101F33;
  }
}

.company-page {
  & + .container-fluid {
    & > h2 {
      margin-bottom: 0;
    }
  }
}

.why-are-we {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -10px 70px -10px;
  @include breakpoint-down(md) {
    display: block;
  }

  &__item-wrap {
    width: 50%;
    padding: 10px;
    @include breakpoint-down(md) {
      width: 100%;
      padding: 5px;
    }
  }

  &__item {
    padding: 30px;
    background: #FFF;
    box-shadow: 0 15px 25px rgba(138, 149, 158, 0.15);
    border-radius: 5px;
    @include breakpoint-down(md) {
      padding: 30px 12px;
    }

    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #101F33;
      margin-bottom: 16px;
    }

    .description {
      font-size: 16px;
      line-height: 20px;
      color: #101F33;
      min-height: 280px;
    }
  }
}

.company-page2 {

  .image-and-info {
    background-color: transparent;
    padding: 0;
    margin-bottom: 80px;
    @include breakpoint-down(md) {
      margin-bottom: 20px;
    }

    .image-and-info__inside-img {
      position: relative;
      @include breakpoint-down(md) {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    .left {
      padding: 0;

      .title-and-subtitle {
        position: absolute;
        background: #F9F9F9;
        padding: 10px 18px;
        text-align: left;
        z-index: 2;
        right: 55px;
        top: 25px;
        @include breakpoint-down(md) {
          position: relative;
          top: 0;
          bottom: 0;
          right: 0;
          width: 83%;
          margin: 15px auto 28px;

          .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 6px;
            color: #101F33;
          }
        }

        &::before {
          content: '';
          position: absolute;
          bottom: -40px;
          background-image: url("/images/linenem.svg");
          right: 0;
          left: -20px;
          height: 30px;
          background-repeat: no-repeat;
          @include breakpoint-down(md) {
            display: none;
          }
        }
      }

      .img {
        width: 430px;
        @include breakpoint-down(md) {
          width: 100%;
          padding: 0 10px;
          height: 350px;
        }

        img {
          max-width: 100%;
        }
      }
    }

    .right {
      ul {
        margin-bottom: 40px;
      }

      button {
        width: 315px;
        @include breakpoint-down(md) {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  .grey-fon {
    background: #F5F7F9;
    padding-top: 80px;
    @include breakpoint-down(md) {
      padding-top: 40px;
    }

    .tariffs {
      margin-bottom: 0;
      padding-bottom: 80px;
      @include breakpoint-down(md) {
        padding-bottom: 40px;
      }
    }
  }

  .how-its-made {
    background: #F5F7F9;
    padding: 80px 0;
    margin-bottom: 80px;
    @include breakpoint-down(md) {
      padding: 40px 0;
      margin-bottom: 20px;
    }
  }

  .asset {
    display: flex;
    margin-bottom: 80px;
    @include breakpoint-down(md) {
      display: block;
      margin-bottom: 40px;
    }

    .left {
      width: 50%;
      text-align: center;
      @include breakpoint-down(md) {
        width: 100%;
      }

      h2 {
        display: none;
        @include breakpoint-down(md) {
          display: block;
          text-align: left;
        }
      }
    }

    .right {
      width: 50%;
      @include breakpoint-down(md) {
        width: 100%;

        h2 {
          display: none;
        }
      }

      .info {
        font-size: 16px;
        line-height: 20px;
        color: #101F33;
        margin-bottom: 40px;
        @include breakpoint-down(md) {
          margin-bottom: 30px;
        }

        a{
          color: #1877F2;
        }
      }
    }

    button {
      width: 315px;
      height: 54px;
      @include breakpoint-down(md) {
        width: 100%;
      }
    }
  }
}

.image-and-info {
  background: #E5E5E5;
  padding: 80px 0;
  margin-bottom: 80px;
  @include breakpoint-down(md) {
    padding: 10px 0 40px;
    margin-bottom: 40px;
  }

  &__inside {
    display: flex;
    @include breakpoint-down(md) {
      display: block;
    }
  }

  .left {
    width: 50%;
    text-align: center;
    padding-top: 80px;
    @include breakpoint-down(md) {
      width: 100%;
      padding: 0 20px;

      img {
        max-width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .right {
    width: 50%;
    @include breakpoint-down(md) {
      width: 100%;

      h2 {
        margin-bottom: 20px;
      }
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        font-size: 16px;
        line-height: 20px;
        color: #101F33;
        display: flex;
        align-items: flex-start;
        margin-bottom: 30px;
        @include breakpoint-down(md) {
          margin-bottom: 16px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          content: '';
          width: 8px;
          height: 8px;
          background: #1877F2;
          border-radius: 50%;
          display: block;
          margin-right: 12px;
          transform: translate(0px, 3px);
          flex-shrink: 0;
        }
      }
    }
  }
}

.blocks {
  &__items {
    display: flex;
    margin: 0 -10px 80px;
    @include breakpoint-down(md) {
      display: block;
      margin: 0 0 40px;
    }
  }

  &__item-wrap {
    width: 25%;
    padding: 0 10px;
    @include breakpoint-down(md) {
      width: 100%;
      padding: 0;
      margin-bottom: 8px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__item {
    background: #FFFFFF;
    box-shadow: 0 15px 25px rgba(138, 149, 158, 0.15);
    border-radius: 5px;
    padding: 18px 15px;
    min-height: 240px;
    font-size: 14px;
    line-height: 17px;
    color: #101F33;

    .img {
      margin-bottom: 20px;
    }

    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #101F33;
      margin-bottom: 16px;
    }
  }
}

.tariffs {
  margin-bottom: 80px;
  @include breakpoint-down(md) {
    margin-bottom: 40px;
  }

  .tariff-block {
    width: 816px;
    margin: 0 auto 40px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 15px 25px rgba(138, 149, 158, 0.15);
    @include breakpoint-down(md) {
      width: 100%;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 40px;
      font-size: 16px;
      line-height: 20px;
      color: #101F33;
      @include breakpoint-down(md) {
        padding: 15px;
        display: block;
        font-size: 14px;
        line-height: 18px;

        .left {
          margin-bottom: 10px;
        }
      }

      &:nth-child(odd) {
        background: #add6ff33;
      }

      .right {
        font-weight: 600;
      }
    }
  }

  .tariff-block-btn-wrap {
    width: 816px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @include breakpoint-down(md) {
      flex-direction: column-reverse;
      width: 100%;
    }

    a {
      margin-right: 20px;

      &:last-of-type {
        margin-right: 0;
      }

      @include breakpoint-down(md) {
        width: 100%;
        margin-bottom: 14px;
        margin-right: 0;

        &:first-child {
          margin-bottom: 0;
        }
      }
    }

    button {
      width: 315px;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include breakpoint-down(md) {
        width: 100%;
        margin-bottom: 14px;
        margin-right: 0;

        &:first-child {
          margin-bottom: 0;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.strategy-wrap-outside {
  padding-top: 80px;
  @include breakpoint-down(md) {
    padding-top: 40px;
  }
}

.strategy-wrap {
  display: flex;
  align-items: flex-start;
  margin: -15px -15px 65px;
  @include breakpoint-down(md) {
    margin: 0 0 40px;
    flex-wrap: wrap;
  }

  .item-wrap {
    width: 33.33333%;
    padding: 15px;
    @include breakpoint-down(md) {
      padding: 0;
      width: 100%;
      margin-bottom: 25px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .item {
      background: #F5F7F9;
      border-radius: 5px;
      padding: 20px 26px 50px;
      box-shadow: 4px 4px 9px #00000038;
    }

    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 35px;
    }

    .subtitle {
      &-item {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;

        &:last-of-type {
          margin-bottom: 10px;
        }

        .img {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px;

          img {
            max-width: 100%;
          }

          &.blue {
            background: #45a1f0;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .description {
    font-size: 14px;
    line-height: 19px;
    padding-left: 45px;
  }
}

.sub-info{
  padding-top: 30px;
  h3{
    margin-bottom: 5px;
    color: #101F33;
  }

  a{
    color: #1877F2;
    &:hover{
      text-decoration: underline;
    }

    @include breakpoint-down(md) {
     font-size: 14px;
    }
  }
}
