.video-yo {
  width: 100%;
  height: 200px;
}

.video-page {
  .video-items {
    display: flex;
    flex-wrap: wrap;
    min-height: 400px;
    margin: -10px;
    opacity: 0;
    transition: opacity .5s ease-in-out;
    padding-bottom: 95px;
    position: relative;

    &.loaded {
      opacity: 1;
    }

    @include breakpoint-down(md) {
      display: block;
    }
  }

  .video-item-outside {
    width: 25%;
    padding: 10px;
    display: flex;

    @include breakpoint-down(md) {
      width: 100%;
    }
  }

  .video-item {
    padding: 10px;
    box-shadow: 4px 4px 9px #00000038;
    transition: all .2s ease-in;
    transform: translate(0, 0);
    border-radius: 5px;

    &:hover {
      box-shadow: 4px 5px 10px #00000038;
      transform: translate(0px, -3px);
    }


    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }

    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #101F33;
      margin-top: 8px;
    }

    .date {
      margin-top: 6px;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #101F33;
      display: none;
    }

    .description {
      height: 0;
      overflow: hidden;
    }
  }
}
