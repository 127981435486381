.hide-desktop{
  display: none ;
  @include breakpoint-down(md){
    display: block;
  }
}

.hide-mobile{
  @include breakpoint-down(md){
    display: none;
  }
}

.investidea-page{

    .start-invest{
        margin-top: 40px;
        @include breakpoint-down(md){
            margin-bottom: 25px;
        }
    }

    .fihished-idea{
        margin-bottom: 40px;
        @include breakpoint-down(md){
            margin-bottom: 25px;
        }
    }

  @include breakpoint-down(md){
    .show-all-idea-translate{
      transform: translate(0);
      margin-bottom: 30px;
      margin-top: 30px;
    }

    .idea__more-link::after{
      border: 0.125rem solid #575756;
    }

    .ideas{
      margin-left: 20px;

      .swiper-slide{
        width: 300px;
      }
    }
  }
}

@include breakpoint-down(md){
  .ideas{
    margin-left: 20px;
    min-height: 480px;

    .swiper-slide{
      width: 300px;
    }
  }
}
