@font-face {
  font-family: 'iconfont';
  src: 	url('#{$path-fonts}/iconfont.ttf') format('truetype'),
    url('#{$path-fonts}/iconfont.woff') format('woff'),
    url('#{$path-fonts}/iconfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-business-and-finance::before {
	content: "\ea01";
}

.icon-check::before {
	content: "\ea02";
}

.icon-clock::before {
	content: "\ea03";
}

.icon-collapse-arrow::before {
	content: "\ea04";
}

.icon-facebook::before {
	content: "\ea05";
}

.icon-filltriangle::before {
	content: "\ea06";
}

.icon-finance-board::before {
	content: "\ea07";
}

.icon-graph::before {
	content: "\ea08";
}

.icon-growth::before {
	content: "\ea09";
}

.icon-honesty::before {
	content: "\ea0a";
}

.icon-in::before {
	content: "\ea0b";
}

.icon-insta::before {
	content: "\ea0c";
}

.icon-instagram::before {
	content: "\ea0d";
}

.icon-left-arrow::before {
	content: "\ea0e";
}

.icon-left::before {
	content: "\ea0f";
}

.icon-list::before {
	content: "\ea10";
}

.icon-menu-burger::before {
	content: "\ea11";
}

.icon-pie-chart::before {
	content: "\ea12";
}

.icon-plus::before {
	content: "\ea13";
}

.icon-right-arrow::before {
	content: "\ea14";
}

.icon-right::before {
	content: "\ea15";
}

.icon-search::before {
	content: "\ea16";
}

.icon-shield::before {
	content: "\ea17";
}

.icon-square-check::before {
	content: "\ea18";
}

.icon-telega::before {
	content: "\ea19";
}

.icon-users::before {
	content: "\ea1a";
}

.icon-vk::before {
	content: "\ea1b";
}

.icon-wallet::before {
	content: "\ea1c";
}

.icon-youtube::before {
	content: "\ea1d";
}