.services{
    background: #383B42;
    background: radial-gradient(50% 50% at 50% 50%, #383B42 0%, #2C2D33 100%);
    min-height: 258px;
    @include breakpoint-down(md){
        padding-top: 60px;
    }

    &__inside{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #3C3F44;
        padding-top: 80px;
        @include breakpoint-down(md){
            display: block;
            padding-top: 43px;
            padding-bottom: 52px;
        }
    }

    &__item{
        display: flex;
        align-items: center;
        color: #fff;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        @include breakpoint-down(md){
            margin-bottom: 33px;
            &:last-of-type{
                margin-bottom: 0;
            }
        }

        .img{
            margin-right: 20px;

            i{
                font-size: 45px;
            }
        }
    }
}
