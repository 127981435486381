// классы-хелперы
// **********************************************************************************

.clearfix {
  @include clearfix;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.hide,
.hidden {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

img {
  vertical-align: middle;
}

.img-fluid {
  @include img-responsive;
}

.img-inline {
  display: inline-block;
}

.img-circle {
  border-radius: 50%;
}

.img-centered {
  overflow: hidden;
  text-align: center;

  img {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    max-height: 100%;
  }
}

.link-unstyled { // ссылка без форматирования
  text-decoration: none !important;
}

.link-block { // блочная ссылка
  @extend .link-unstyled;
  display: block;
}

.link-pseudo {
  > span {
    display: inline-block;
    vertical-align: middle;
  }
}

.text-hide {
  @include text-hide;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

// Transformation
.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.fade {
  opacity: 0;
  transition: opacity .15s linear;

  &.in {
    opacity: 1;
  }
}

.text-ellipsis {
  @include text-overflow;
}

.js-text-ellipsis {
  overflow: hidden;
}

.ratio {
  position: relative;
  overflow: hidden;

  > div,
  > img {
    @include stretch;
    width: 100%;
    height: 100%;
  }
}

.column {
  float: left;
  min-height: 1px;
}

.row-alt {
  font-size: 0;
}

.column-alt {
  display: inline-block;
  vertical-align: top;
  font-size: $font-size-base;
  line-height: $line-height-base;
  float: none;
}

.bg-cover {
  background-size: cover;
  background-position: 50% 50%;
}

.vertical-align-middle-mode3 {
  position: absolute;
  transform: translateY(-50%) translateX(-50%);
  top: 50%;
  left: 50%;
  width: 100%;
}

.vertical-align-middle-mode2 {
  display: table;
  width: 100%;
  height: 100%;

  > * {
    display: table-cell;
    vertical-align: middle;
  }
}

.vertical-align-middle {
  > * {
    display: inline-block;
    vertical-align: middle;
  }

  &::after {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    content: '';
  }
}


.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    @include stretch-y;
    left: 0;
    height: 100%;
    width: 100%;
    border: 0;
  }
}

// Modifier class for 16:9 aspect ratio
.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

// Modifier class for 4:3 aspect ratio
.embed-responsive-4by3 {
  padding-bottom: 75%;
}

.loader-wrap {
  position: relative;
}

.loader {
  position: absolute;
  height: 6px;
  left: 0;
  right: 0;
  top: 0;
  border-radius: 6px;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: opacity .03s ease-in-out;
    animation: .7s ease-in-out  loader-animation;
    background-color: #1877F2;
  }
}

@keyframes loader-animation {
  0% {
    right: 100%;
    opacity: .5;
  }
  25%{
    opacity: 1;
  }
  50%{
    opacity: .5;
  }
  75%{
    opacity: 1;
  }
  100% {
    opacity: .5;
    right: 0;
  }
}