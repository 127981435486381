.contacts-page {
    background: #F5F7F9;


    h2{
        @include breakpoint-down(md){
            margin-bottom: 16px;
        }
    }

    .contacts-wrap {
        display: flex;
        padding-bottom: 80px;
        margin: -20px;
        min-height: 50vh;

        @include breakpoint-down(md){
            flex-direction: column;
        }
    }

    .contact-item{
        background: #FFFFFF;
        box-shadow: 0 15px 25px rgb(138 149 158 / 15%);
        border-radius: 5px;
        padding: 25px;
        height: 100%;

        &-wrap-outside{
            padding: 20px;
            width: 50%;
            max-height: 270px;
            @include breakpoint-down(md){
                width: 100%;
            }
        }

        .link-wrap{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 20px;
            height: 45px;
        }

        .phone {
            margin-bottom: 5px;
            display: block;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #1877F2;
        }

        .email {
            margin-bottom: 5px;
            display: block;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #1877F2;
        }

        .info {
            font-size: 14px;
            line-height: 17px;
            color: #101F33;

            p:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        .map-link {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #1877F2;
            display: flex;
            align-items: center;
            margin-top: 20px;

            i {
                font-size: 14px;
                margin-left: 20px;
                transition: all .2s ease-in-out;
                transform: translate(0, 0);
            }

            &:hover {
                i {
                    transform: translate(10px, 0);
                }
            }
        }
    }
}
