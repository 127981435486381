@import "./helpers/normalize";
@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../../node_modules/swiper/swiper-bundle.min.css";
@import "../../node_modules/@fancyapps/ui/dist/fancybox.css";

// Core variables and mixins
@import "./helpers/functions";
@import "./helpers/variables";
@import "./helpers/variables-responsive";

// Mixins
@import "./helpers/mixins";
@import "mixins/breakpoints";
@import "mixins/grid";

// Shared components
@import "./helpers/fonts";
@import "./helpers/icons";

// Core CSS
@import "./helpers/reboot";
@import "./helpers/type";
@import "./helpers/helpers";
@import "./helpers/grid";
@import "./helpers/flex";
@import "./helpers/masonry";
@import "./helpers/tables";
@import "./helpers/forms";
@import "./helpers/buttons";
@import "./helpers/tabs";
@import "./helpers/layout";
@import "./helpers/carousels";
@import "./helpers/collapse";


// Components
@import "./components/main";
