// переменные проекта

$font-family-base: "Montserrat", Helvetica, Arial, sans-serif !default;

$path-fonts:    "../../fonts"; // путь к директории со шрифтами

$font-size-base: 16px !default;
$line-height-base: 1.2 !default;

$footer-height: 100px;
$header-height: 60px;


// переменные Цвета
// *************************************************************************
$darkblue: #0E2773;
$blue: #1438A5;
$lightblue: #1455D8;
$black: #1D1D1B;
$red: #BE1736;
$gray: 575756;

$green: greeb;
$orange: orange;
$pink: pink;
$cyan: cyan;
$purple: purple;
$yellow: yellow;


$brand-primary: $purple;
$brand-secondary: $green;

// --------------------------------------------------
$body-bg: white !default;
$text-color: black !default;

$link-color: $brand-primary !default;
$link-hover-color: $brand-secondary !default;

// --------------------------------------------------
$input-border: $cyan !default;
$input-bg: transparent !default;
$input-bg-disabled: $gray !default;
$input-color: black !default;
$input-border-focus: $blue !default;
$input-color-placeholder: $red !default;

