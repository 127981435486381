$btn-font-size: $font-size-base !default;
$btn-line-height: $font-size-base !default;
$btn-box-shadow: inset 0 3px 5px rgba(black, .125);
$btn-disabled-opacity: .65;
$btn-border-width: 1px !default;
// *************************************************************************

.btn {
    display: inline-block;
    margin-bottom: 0;
    //font-weight: normal;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: $btn-border-width solid transparent;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    user-select: none;
    transition-property: box-shadow, background-color;
    transition-duration: .2s;
    outline: none !important;
    text-decoration: none !important;
    padding: 11px 17px 12px;
    min-width: 100px;
    @include breakpoint-down(md){
        padding: 17px 20px;
    }

    &:active,
    &.active {
        box-shadow: $btn-box-shadow;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        cursor: not-allowed;
        opacity: $btn-disabled-opacity;
        box-shadow: none;
    }

    &_fill-blue {
        @extend .btn;
        background-color: #1877F2;
    }

    &_blue-border {
        @extend .btn;
        background-color: transparent;
        border: 1px solid #1877F2;
    }

    &.white-color {
        color: #fff;
    }

    &.blue-color {
        color: #1877F2;
    }

    &.animate-btn{
        transition: all .2s ease-in-out;
        transform: translate(0);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0);

        &:hover{
            transform: translate(0, -1px);
            box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
        }
    }
}

a.btn {
    &.disabled,
    fieldset[disabled] & {
        pointer-events: none;
    }
}

.btn-block {
    display: block;
    width: 100%;
}

input[type="submit"],
input[type="reset"],
input[type="button"] {
    &.btn-block {
        width: 100%;
    }
}
