.top-banner {
    background: #383B42;
    background: radial-gradient(50% 50% at 50% 50%, #383B42 0%, #2C2D33 100%);
    padding: 30px 0;
    min-height: 470px;
    margin-bottom: 80px;
    display: flex;
    align-items: center;
    &.none-bottom-offset{
        margin-bottom: 0;
        .top-banner__inside{
            @include breakpoint-down(md){
                h1{
                    br{
                        display: none;
                    }
                }
            }
        }
    }

    @include breakpoint-down(md){
        margin-bottom: 40px;
    }

    &__inside{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;

        @include breakpoint-down(md){
            flex-direction: column;
            .btn.animate-btn{
                width: 100%;
                min-width: 100%;
            }
        }
    }

    &__img{
        .img{
            height: 370px;
            @include breakpoint-down(md){
                height: 290px;
            }
            img{
              max-width: 100%;
              height: 100%;
              object-fit: contain;
            }
        }
        @include breakpoint-down(md){
            position: relative;
            margin: 0 auto 37px;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        & + div{
            width: 60%;
            @include breakpoint-down(md){
                width: 100%;
            }
        }
    }

    h1 {
        margin-bottom: 18px;
        @include breakpoint-down(md){
            margin-bottom: 16px;
            font-size: 30px;
            line-height: 37px;
        }
    }

    &__description {
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        color: #FFF;
        width: 70%;
        @include breakpoint-down(md){
            font-weight: normal;
            width: 100%;
        }

        & + button{
            margin-top: 40px;
            @include breakpoint-down(md){
                margin: 25px 0;
            }
        }
    }
}

.swiper-container{
    overflow: hidden;
}
