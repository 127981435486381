.blog-page {

    .blog {
        &__wrap {
            display: flex;
            flex-wrap: wrap;
            margin: -15px -30px 80px;
            @include breakpoint-down(md) {
                margin: 0 0 40px;
            }
        }

        &__item-wrap {
            width: 33.3333333%;
            padding: 15px 30px;
            @include breakpoint-down(md) {
                width: 100%;
                padding: 10px 10px 50px;
            }

            .blue {
                background: #1877F2;
            }

            .dark-blue {
                background: #0E2773;
            }

            .red{
                background: #EE5252;
            }
        }

        &__item {
            display: block;
            background: #fff;
            padding: 15px 15px 25px;
            border-radius: 5px;
            box-shadow: 4px 4px 9px #00000038;
            transition: all .2s ease-in;
            transform: translate(0, 0);

            &:hover {
                box-shadow: 4px 5px 10px #00000038;
                transform: translate(0px, -3px);
            }

            .more{
                color: #1877F2;
                font-size: 12px;
                line-height: 12px;
                text-align: right;
                padding-top: 25px;
                font-weight: 600;
            }
        }

        &__author {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
        }

        &__img {
            height: 200px;
            margin-bottom: 6px;
            position: relative;

            img {
                max-width: 100%;
                height: 100%;
            }

            .title{
                font-weight: 600;
                font-size: 12px;
                line-height: 14px;
                padding: 6px 10px;
                color: #fff;
                position: absolute;
                left: -15px;
                top: 50%;
                transform: translate(0, -50%);
                z-index: 1;
            }
        }

        &__date-rubrik {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 25px;

            .date{
                font-weight: 600;
                font-size: 12px;
                line-height: 12px;
                display: flex;
                align-items: center;
                height: 24px;
                padding: 0 20px;
                color: #fff;
                border-radius: 15px;
            }
        }

        &__title {
            font-weight: 600;
            font-size: 17px;
            line-height: 22px;
            color: #000;
            margin-bottom: 10px;
        }

        &__description {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #000;
            display: none;
        }
    }
}
