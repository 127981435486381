.founder-page {
    .top-banner__img {
        height: 390px;
        top: -120px;
        right: 0;
        @include breakpoint-down(md){
            height: auto;
            top: 0;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .top-banner__description{
        @include breakpoint-down(md){
            width: 100%;
        }
    }

    .founder {
        &__item {
            background: #FFF;
            display: flex;
            margin-bottom: 50px;

            @include breakpoint-down(md) {
                flex-direction: column;
                align-items: center;
                padding: 0;
            }
        }

        &__img {
            width: 300px;
            height: 300px;
            margin-right: 100px;
            border-radius: 5px;
            overflow: hidden;
            box-shadow: 10px 10px 25px rgba(138, 149, 158, 0.4);
            @include breakpoint-down(md) {
                margin-right: 0;
                margin-bottom: 20px;
            }

            img {
                max-width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(100% - 400px);
            padding-right: 60px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: #101F33;

            @include breakpoint-down(md) {
                width: 100%;
                padding: 0;
            }

            &_bottom {
                padding: 0 0 16px 26px;
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                @include breakpoint-down(md) {
                    padding: 20px 0;
                    align-items: center;
                    justify-content: space-between;
                }

                .social-links a {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    padding: .625rem;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: 1px solid rgba(87, 87, 86, .4);
                    font-size: 17px;
                    color: rgba(87, 87, 86, .4);
                    margin-right: 20px;
                    transition: all .2s ease-in-out;

                    &:hover {
                        border: 1px solid #1877F2;
                        color: #1877F2;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }

                .email {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    color: #101F33;
                    transition: color .2s ease-in-out;
                    &:hover{
                        color: #1877F2;
                    }

                    @include breakpoint-down(md) {
                        padding-right: 0;
                    }
                }
            }

            .title {
                font-weight: 600;
                font-size: 28px;
                line-height: 33px;
                margin-bottom: 10px;
                color: #1877F2;
            }

            .subtitle {
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #101F33;
                margin-bottom: 45px;
            }

            p:not(:last-child) {
                margin-bottom: 40px;
            }
        }

        &__about-info {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #101F33;
            padding-bottom: 80px;

            .bold {
                font-weight: 700;
            }

            p:not(:last-child) {
                margin-bottom: 28px;
            }
        }
    }
}
