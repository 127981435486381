.bottom-slide {
    &__info-wrap {
        display: flex;
        background: #FFF;
        box-shadow: 0px 15px 25px rgba(138, 149, 158, 0.15);
        border-radius: 5px;
        justify-content: center;
        padding: 42px 0 58px;
        margin: -50px 0 80px;
        @include breakpoint-down(md){
            margin: 0;
            display: block;
            padding: 45px 0 40px;
            box-shadow: 0 15px 25px rgba(138, 149, 158, 0);
        }
    }

    &__info{
        margin-right: 50px;
        margin-left: 50px;

        @include breakpoint-down(md){
            margin-right: 0;
            margin-bottom: 35px;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }

    &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 34px;
        line-height: 41px;
        color: #101F33;
        margin-bottom: 14px;
        display: flex;
        align-items: center;
    }

    &__img{
        margin-right: 22px;
        display: flex;
        align-items: center;
    }

    &__description {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #2C2D33;
        @include breakpoint-down(md){
            br{
                display: none;
            }
        }
    }
}
