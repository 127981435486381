.activity-page {
    margin-bottom: 80px;
    @include breakpoint-down(md) {
        margin-bottom: 40px;

        h2.mb50 {
            margin-bottom: 30px;
            width: 320px;
        }

        .mb100 {
            margin-bottom: 0;
        }
    }

    .show-all-idea {
        margin: 40px 0 100px;
        color: #575756;
        border: 2px solid #575756;
        @include breakpoint-down(md) {
            margin: 0 0 50px;
        }
    }

    .activity {
        &-items {
            margin: -25px -30px;
            padding-bottom: 50px;
            @include breakpoint-down(md) {
                margin: 0;
            }

            &.disabled {
                .activity-item-wrap-outside {
                    opacity: .3;

                    .event-slider__format{
                        display: none;
                    }
                    .event-slider__btn{
                        display: none;
                    }
                }
            }
        }

        &-item-wrap-outside {
            padding: 25px 30px;
            @include breakpoint-down(md) {
                padding: 0 0 20px 0;
                position: relative;
            }

            .event-slider__format{
                font-size: .875rem;
                line-height: 1.0625rem;
                color: #1877f2;
                text-transform: uppercase;
                margin-top: 0.5rem;

                @include breakpoint-down(md) {
                    position: absolute;
                    top: 290px;
                    left: 30px;
                    font-size: 10px;
                }
            }
        }

        &-item {
            filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0));
            transition: all .3s ease-in-out;
            @include breakpoint-down(md) {
                display: block;
            }

            &:hover {
                filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.1));
            }

            .event-slider__wrap {
                display: flex;
                @include breakpoint-down(md) {
                    height: 100%;
                    display: block;
                    margin-bottom: 30px;
                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
                }
            }

            .event-slider__img {
                width: 50%;
                border-radius: 0.3125rem;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                @include breakpoint-down(md) {
                    width: 100%;
                }
            }

            .event-slider__info {
                width: 50%;
                padding: 0 0 4.375rem 2.5rem;
                position: relative;
                @include breakpoint-down(md) {
                    width: 100%;
                    padding: 30px;
                }

                .event-slider__title{
                    font-weight: 600;
                    font-size: 1.25rem;
                    line-height: 1.5rem;
                    margin-bottom: 1.625rem;
                }

                .event-slider__desc{
                    color: #101f33;
                    font-size: 1rem;
                    line-height: 1.25rem;
                }

                .event-slider__btn {
                    height: 3.375rem;
                    padding: 0 5.25rem;
                    position: absolute;
                    bottom: 0;
                    left: 2.5rem;

                    @include breakpoint-down(md) {
                        width: 100%;
                        padding: 0;
                        margin-top: 20px;
                        position: initial;
                    }
                }

            }
        }
    }
}
