.header {
    padding: 5px 0;
    z-index: 99;
    background-color: #fff;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: $header-height;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: all .3s ease-in-out;

    &_inside {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .left {
        display: flex;
        align-items: center;

        @include breakpoint-down(md) {
            flex-grow: 1;
            justify-content: space-between;
        }
    }

    .right {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: space-between;

        button:not(:last-of-type) {
            margin-right: 20px;
        }

        button {
            @include breakpoint-down(md) {
                background-color: transparent;
                padding: 0;
                margin-bottom: 20px;

                &:first-child {
                    color: #1438A5;
                    margin-top: 20px;
                }

                &:last-child {
                    color: #1877F2;
                    text-align: left;
                    margin-bottom: 0;
                }
            }
        }

        @include breakpoint-down(md) {
            position: fixed;
            background-color: #f8f8f8;
            flex-direction: column;
            left: 0;
            right: 0;
            top: 60px;
            bottom: 0;
            align-items: flex-start;
            padding: 20px 20px;
            transition: transform .3s ease-in-out, opacity .2s ease-in-out;
            overflow: hidden;
            z-index: -1;
            opacity: 0;
            transform: translate(100%, 0);

            &.show-menu {
                overflow: auto;
                z-index: 1;
                opacity: 1;
                transform: translate(0);
            }

        }
    }

    .pbull-login{
        position: relative;

        a{
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }
    }
}

body {
    padding-top: $header-height;
}

.logo {
    margin-right: 50px;
    width: 112px;

    img {
        max-width: 100%;
    }

    @include breakpoint-down(md) {
        height: 50px;
        width: 100px;

        img {
            max-width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.menu-trigger {
    display: none;
    color: #1877F2;
    font-size: 30px;
    line-height: 10px;

    @include breakpoint-down(md) {
        display: block;
    }
}

.modal.fade.show {
    opacity: 1;
}

.modal-content {
    background: #FFFFFF;
    border-radius: 10px;
    border: none;
    padding: 20px 100px 30px;
    @include breakpoint-down(md) {
        padding: 20px;
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 700px;
        margin: 1.75rem auto;
    }
}

.modal-header {
    position: relative;
    padding: 0;
    border: none;
    justify-content: center;

    .close {
        position: absolute;
        right: -70px;
        top: 0;
        @include breakpoint-down(md) {
            right: -10px;
            top: -13px;
        }
    }
}

.modal-body {
    padding: 0;

    input {
        width: 100%;
        display: block;
        margin-bottom: 20px;
        background: rgba(196, 196, 196, 0.2);
        border-radius: 10px;
        border: none;
        height: 50px;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #000000;
    }
}

.hide-header {
    .header {
        top: -100%;
    }
}

.modal-title {
    text-align: center;
    margin-bottom: 20px;
    border: none;
}

.modal-footer {
    padding: 0;
    border: none;
    justify-content: center;

    button {
        min-width: 260px;
    }
}
